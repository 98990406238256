import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IconComponent } from '../icon';

@Component({
  selector: 'cca-show-more-less',
  imports: [IconComponent],
  templateUrl: './show-more-less.component.html',
  styleUrl: './show-more-less.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShowMoreLessComponent {
  @Input() showMoreLabel: string | undefined;
  @Input() showLessLabel: string | undefined;
  @Input() isShowMore = true;
  @Output() isShowMoreChanged = new EventEmitter<boolean>();
}
